<template>
    <div class="mainContainer d-flex">
        <!--Delete Category Modal-->
        <b-modal v-model="deleteCategoryModal" title="Delete Category">
            Type 'category' to delete:
            <b-form-input v-model="deleteVerificationString"/>
            <template slot="modal-footer">
                <b-button @click="deleteCategoryModal = false" size="sm">Cancel</b-button>
                <b-button @click="deleteCategory" size="sm" variant="danger"
                          :disabled="deleteVerificationString !== 'category'">Delete</b-button>
            </template>
        </b-modal>

        <!--Delete Template Modal-->
        <b-modal v-model="deleteTemplateModal" title="Delete Template">
            Type 'template' to delete:
            <b-form-input autofocus v-model="deleteVerificationString" @keyup.enter="deleteTemplate"/>
            <template slot="modal-footer">
                <b-button @click="deleteTemplateModal = false" size="sm">Cancel</b-button>
                <b-button @click="deleteTemplate" size="sm" variant="danger"
                          :disabled="deleteVerificationString !== 'template'">Delete</b-button>
            </template>
        </b-modal>

        <!-- PDF Preview Modal -->
        <b-modal size="xl" title="PDF Preview" class="modal-dark" v-model="showPdf" hide-footer>
            <div style="display: flex; justify-content: center">
                <PdfPreview :template="template.categories"
                            :categories="[]"
                            :isTemplate="true"/>
            </div>
        </b-modal>

        <!-- Template Creator -->
        <div style="flex: 3; height: 100%; padding: 10px">
            <b-card style="height: 100%" no-body class="categoryBackgroundColor">

                <!-- Template Creator Header -->
                <div slot="header">
                    <div class="d-flex w-100 justify-content-between align-items-baseline">
                        <h5 style="margin-bottom: 0">
                            <b>Template Creator</b> <span>&nbsp;</span>
                            <fa-icon :icon="['fas', 'question-circle']" @mouseenter="displayInfo = true" @mouseleave="displayInfo = false"/>
                            <span v-if="displayInfo === true" class="infoBox">
                                <span class="infoTitle"> Adding Fields</span>
                                <span class="infoText">{{addingFields}}</span><br><br>
                                <span class="infoTitle"> Removing Fields</span>
                                <span class="infoText">{{removingFields}}</span><br><br>
                                <span class="infoTitle"> Editing Fields</span>
                                <span class="infoText">{{editingFields}}</span><br><br>
                                <span class="infoTitle"> Saving Template</span>
                                <span class="infoText">{{savingTemplate}}</span><br><br>
                                <span class="infoTitle"> Single Item Category</span>
                                <span class="infoText">{{singeItemCategory}}</span><br><br>
                            </span>
                        </h5>
                        <div style="display: flex">
                            <b-button size="sm" variant="secondary" @click="showPdf = true" style="margin-right: 10px">Preview Pdf</b-button>
                            <b-button size="sm" variant="primary" @click="addCategory" style="margin-right: 10px"> Add Category </b-button>
                            <b-button size="sm" variant="danger" @click="openDeleteTemplateModal" style="margin-right: 10px"> Delete Template</b-button>
                            <b-button size="sm" variant="success" @click="postPutTemplate" :disabled="preventBadSave"> Save Template</b-button>
                        </div>
                    </div>
                </div>

                <!-- Template Creator Body -->
                <div class="h-100 overflow-auto" style="padding: 20px">
                    <div v-for="(category, index) in template.categories" :key="category._id">
                        <!-- Category Card Form -->
                        <b-card class="itemBackgroundColor" no-body style="border: 1px">
                            <!-- Category Card Form Header -->
                            <div slot="header" class="w-100 d-flex justify-content-between align-items-baseline" v-b-toggle="'category_' + index">
                                <div class="d-flex">
                                    <b-input-group size="sm" style="margin-right: 10px" @click.stop :class="category.name !== '' ? '' : 'border border-danger rounded'">
                                        <b-input-group-prepend>
                                            <b-input-group-text style="width: 100%; display: flex; justify-content: center;">
                                                <b> Category Name </b>
                                            </b-input-group-text>
                                        </b-input-group-prepend>
                                        <b-form-input v-model="category.name" :formatter="formatName" class="input-height" @keydown.stop style="height: inherit"/>
                                    </b-input-group>
                                    <b-input-group size="sm" style="margin-right: 10px" @click.stop :class="category.item.name !== '' ? '' : 'border border-danger rounded'">
                                        <b-input-group-prepend>
                                            <b-input-group-text style="width: 100%; display: flex; justify-content: center;">
                                                <b> Item Heading </b>
                                            </b-input-group-text>
                                        </b-input-group-prepend>
                                        <b-form-input v-model="category.item.name" @keydown.stop
                                                      :formatter="formatName" class="input-height" style="height: inherit"/>
                                    </b-input-group>
                                    <b-checkbox style="margin-top: 5px;" v-model="template.categories[index].singleItem" switch/>
                                    <span style="white-space: nowrap; margin-top: 5px;">Single Item Category</span>
                                </div>
                                <div class="d-flex align-items-center">
                                    <fa-icon v-if="template.categories.length > 1" :icon="['fas', 'trash-can']" size="lg"
                                       @click.stop="openDeleteCategoryModal(category._id)" style="margin-right: 15px"/>
                                    <fa-icon :icon="['fas', 'caret-down']" class="when-open"/>
                                    <fa-icon :icon="['fas', 'caret-up']" class="when-closed"/>
                                </div>
                            </div>
                            <!-- Category Card Form Body -->
                            <div @dragover.prevent>
                                <b-collapse :id="'category_' + index" visible style="padding: 15px">
                                    <div v-if="category.item.fields.length === 0 && categoryDragHover !== category._id"
                                         style="padding: 15px;"
                                         class="w-100 d-flex align-items-center justify-content-center"
                                         @dragover.stop="categoryDragHover = category._id">
                                        <i style="color: #f86c6b"> 1 Field Required</i>
                                    </div>
                                    <draggable v-model="category.item.fields" group="fields" style="display: flex; flex-wrap: wrap;" @change="e => addFieldEvent(e, category._id)">
                                        <b-col v-for="field in category.item.fields"
                                               :key="field._id"
                                               :sm="field.size"
                                               style="padding: 5px; cursor: pointer;"
                                               @click="editFieldMode(category._id, field._id, field.values)">
                                            <div style="padding-left: 3px"
                                                 :title="field.required && field.editable ? 'Required' : field.required === false ? '' : 'Required & Not Editable'"
                                                 v-if="field.type !== 'address' && field.type !== 'inmate' && field.type !== 'officer'">
                                                <b>{{field.name + (field.required ? '*' : '') + (field.editable ? '' : '*')}}</b>
                                            </div>
                                            <b-input-group v-if="field.type === 'textBox' || field.type === 'phoneNumber' || field.type === 'date' || field.type === 'number' ||  field.type === 'time' || field.type === 'dateTime'" :class="isSelectedField(category._id, field._id)">
                                                <b-form-input style="cursor: pointer;"
                                                              disabled
                                                              class="input-height fieldBackgroundColor"
                                                              :placeholder="field.description"/>
                                                <b-input-group-append v-if="field.type === 'textBox'">
                                                    <b-input-group-text style="padding: 1px 15px;">
                                                        <span style="font-style: italic; font-weight: bold; font-size: 20px;">T</span>
                                                    </b-input-group-text>
                                                </b-input-group-append>
                                                <b-input-group-append v-if="field.type === 'phoneNumber'">
                                                    <b-input-group-text>
                                                        <fa-icon :icon="['fas', 'phone']" fixed-width/>
                                                    </b-input-group-text>
                                                </b-input-group-append>
                                                <b-input-group-append v-if="field.type === 'date'">
                                                    <b-input-group-text>
                                                        <fa-icon :icon="['fas', 'calendar']" fixed-width/>
                                                    </b-input-group-text>
                                                </b-input-group-append>
                                                <b-input-group-append v-if="field.type === 'dateTime'" s>
                                                    <b-input-group-text style="padding: 3px 4px;">
                                                        <fa-icon :icon="['fas', 'calendar']" fixed-width style="font-size: 11px; position: relative; top: -6px;"/>
                                                        <span style="font-size: 18px; position: relative; transform: rotate(35deg); top: -2px; left:2px;">|</span>
                                                        <fa-icon :icon="['far', 'clock']" fixed-width style="font-size: 11px; position: relative; top: 6px;"/>
                                                    </b-input-group-text>
                                                </b-input-group-append>
                                                <b-input-group-append v-if="field.type === 'number'">
                                                    <b-input-group-text>
                                                        <fa-icon :icon="['fas', 'hashtag']" fixed-width/>
                                                    </b-input-group-text>
                                                </b-input-group-append>
                                                <b-input-group-append v-if="field.type === 'time'">
                                                    <b-input-group-text>
                                                        <fa-icon :icon="['far', 'clock']" fixed-width/>
                                                    </b-input-group-text>
                                                </b-input-group-append>
                                            </b-input-group>
                                            <b-input-group v-if="field.type === 'richTextBox'" :class="isSelectedField(category._id, field._id)">
                                                <b-form-textarea disabled style="cursor: pointer;"
                                                                 class="form-control rounded-0 input-height fieldBackgroundColor"
                                                                 :placeholder="field.description"
                                                                 no-resize
                                                                 rows="3"
                                                                 type="text"/>
                                            </b-input-group>
                                            <b-input-group v-if="field.type === 'checkbox'" :class="isSelectedField(category._id, field._id)">
                                                <b-input-group-text class="w-100">
                                                    <b-checkbox disabled style="cursor: pointer"/> {{field.name + (field.required ? '*' : '')}}
                                                </b-input-group-text>
                                            </b-input-group>
                                            <b-input-group v-if="field.type.includes('ropdownBox')" :class="isSelectedField(category._id, field._id)">
                                                <b-form-select style="cursor: pointer;" class="input-height fieldBackgroundColor">
                                                    <b-form-select-option v-for="(value, index) in field.values" value="value" :key="'dropdown' + index" :id="'value1-' + index" disabled>{{value}}</b-form-select-option>
                                                </b-form-select>
                                                <b-input-group-append>
                                                    <b-input-group-text>
                                                        <fa-icon :icon="['fas', 'caret-down']" fixed-width/>
                                                    </b-input-group-text>
                                                </b-input-group-append>
                                            </b-input-group>
                                            <b-input-group v-if="field.type === 'spacer'" :class="isSelectedField(category._id, field._id)">
                                                <b-form-input style="background-color: #121245; cursor: pointer;" disabled placeholder="Spacer" class="input-height"/>
                                            </b-input-group>
                                            <b-card v-if="field.type === 'address'" disabled="true" :class="isSelectedField(category._id, field._id)" style="margin-bottom: 0;" class="bigFieldBackgroundColor">
                                                <div slot="header">
                                                    <span><b>{{ field.name + (field.required ? '*' : '') + (field.editable ? '' : '*')}}</b></span>
                                                    <span v-if="field.description !== ''" class="text-muted"> ({{field.description}})</span>
                                                </div>
                                                <div>
                                                    <b-row>
                                                        <b-col sm="7">
                                                            <div style="padding-left: 3px;"><b>Address</b></div>
                                                            <b-input-group style="margin-bottom: 10px">
                                                                <b-form-input placeholder="Enter Address" class="input-height fieldBackgroundColor"
                                                                              disabled/>
                                                            </b-input-group>
                                                        </b-col>
                                                        <b-col sm="5">
                                                            <div style="padding-left: 3px;"><b>Apartment/Unit</b></div>
                                                            <b-input-group>
                                                                <b-form-input disabled class="input-height fieldBackgroundColor"/>
                                                            </b-input-group>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row>
                                                        <b-col>
                                                            <div style="padding-left: 3px;"><b>City</b></div>
                                                            <b-input-group>
                                                                <b-form-input disabled class="input-height fieldBackgroundColor"/>
                                                            </b-input-group>
                                                        </b-col>
                                                        <b-col>
                                                            <div style="padding-left: 3px;"><b>State</b></div>
                                                            <b-input-group>
                                                                <b-form-input disabled class="input-height fieldBackgroundColor"/>
                                                            </b-input-group>
                                                        </b-col>
                                                        <b-col>
                                                            <div style="padding-left: 3px;"><b>Zip</b></div>
                                                            <b-input-group>
                                                                <b-form-input disabled class="input-height fieldBackgroundColor"/>
                                                            </b-input-group>
                                                        </b-col>
                                                    </b-row>
                                                </div>
                                            </b-card>
                                            <b-card v-if="field.type === 'inmate'" disabled="true" :class="isSelectedField(category._id, field._id)" style="margin-bottom: 0;" class="bigFieldBackgroundColor">
                                                <div slot="header" class="d-flex align-items-baseline justify-content-between">
                                                    <div>
                                                        <span><b>{{field.name}}</b></span>
                                                        <span v-if="field.description !== ''" class="text-muted"> ({{field.description}})</span>
                                                    </div>
                                                    <fa-icon :icon="['fas', 'magnifying-glass']"/>
                                                </div>
                                                <div style="display: flex">
                                                    <div style="flex: 3; padding-right: 30px;">
                                                        <div style="padding-left: 3px;"><b>Last Name</b></div>
                                                        <b-input-group>
                                                            <b-form-input disabled class="input-height fieldBackgroundColor"/>
                                                        </b-input-group>
                                                    </div>
                                                    <div style="flex: 3; padding-right: 30px;">
                                                        <div style="padding-left: 3px;"><b>First Name</b></div>
                                                        <b-input-group style="margin-bottom: 10px">
                                                            <b-form-input disabled class="input-height fieldBackgroundColor"/>
                                                        </b-input-group>
                                                    </div>
                                                    <div style="flex: 2">
                                                        <div style="padding-left: 3px;"><b>Middle Name</b></div>
                                                        <b-input-group>
                                                            <b-form-input disabled class="input-height fieldBackgroundColor"/>
                                                        </b-input-group>
                                                    </div>
                                                </div>
                                                <div style="display: flex; gap: 30px">
                                                    <div style="flex: 1">
                                                        <div style="padding-left: 3px;"><b>MID</b></div>
                                                        <b-input-group>
                                                            <b-form-input disabled class="input-height fieldBackgroundColor"/>
                                                        </b-input-group>
                                                    </div>
                                                    <div style="flex: 1">
                                                        <div style="padding-left: 3px;"><b>DOB</b></div>
                                                        <b-input-group>
                                                            <b-form-input disabled class="input-height fieldBackgroundColor"/>
                                                        </b-input-group>
                                                    </div>
                                                    <div style="flex: 1">
                                                        <div style="padding-left: 3px;"><b>SSN</b></div>
                                                        <b-input-group>
                                                            <b-form-input disabled class="input-height fieldBackgroundColor"/>
                                                        </b-input-group>
                                                    </div>
                                                </div>
                                            </b-card>
                                            <b-card v-if="field.type === 'officer'" disabled="true" :class="isSelectedField(category._id, field._id)" style="margin-bottom: 0;" class="bigFieldBackgroundColor">
                                                <div slot="header" class="d-flex align-items-baseline justify-content-between">
                                                    <div>
                                                        <span><b>{{field.name}}</b></span>
                                                        <span v-if="field.description !== ''" class="text-muted"> ({{field.description}})</span>
                                                    </div>
                                                    <fa-icon :icon="['fas', 'magnifying-glass']"/>
                                                </div>
                                                <div style="display: flex">
                                                    <div style="flex: 3; padding-right: 30px;">
                                                        <div style="padding-left: 3px;"><b>Last Name</b></div>
                                                        <b-input-group>
                                                            <b-form-input disabled class="input-height fieldBackgroundColor"/>
                                                        </b-input-group>
                                                    </div>
                                                    <div style="flex: 3; padding-right: 30px;">
                                                        <div style="padding-left: 3px;"><b>First Name</b></div>
                                                        <b-input-group style="margin-bottom: 10px">
                                                            <b-form-input disabled class="input-height fieldBackgroundColor"/>
                                                        </b-input-group>
                                                    </div>
                                                    <div style="flex: 2;">
                                                        <div style="padding-left: 3px;"><b>Star #</b></div>
                                                        <b-input-group>
                                                            <b-form-input disabled class="input-height fieldBackgroundColor"/>
                                                        </b-input-group>
                                                    </div>
                                                </div>
                                            </b-card>
                                        </b-col>
                                    </draggable>
                                </b-collapse>
                            </div>
                        </b-card>
                    </div>
                </div>
            </b-card>
        </div>

        <!-- Template Info Card / Fields' Card -->
        <div style="flex: 1; height: 100%"
             class="d-flex flex-column justify-content-between align-items-baseline">

            <!-- Template Info Card -->
            <div class="templateInfo" style="padding-bottom: 10px">
                <b-card style="height: 100%; border: 1px" class="categoryBackgroundColor">

                    <!-- Template Info Card Header -->
                    <div slot="header">
                        <div style="height: 29px" class="d-flex w-100 justify-content-between align-items-center">
                            <h5 style="margin-bottom: 0"><b>Template Info</b></h5>
                        </div>
                    </div>

                    <!-- Template Info Card Body -->
                    <div style="margin-bottom: 20px">
                        <h5 style="margin-right: 10px">Name:</h5>
                        <b-form-input :class="template.name !== '' ? '' : 'border border-danger rounded'" v-model="template.name" :formatter="formatName" class="editFieldBackgroundColor"/>
                    </div>
                    <h5 style="margin-right: 10px">Description:</h5>
                    <b-form-textarea rows="3" v-model="template.description" no-resize :formatter="formatDescription" class="editFieldBackgroundColor"/>
                </b-card>
            </div>

            <!-- Fields' Card -->
            <div class="fieldsInfo">
                <b-card bg-variant="dark" style="height: 100%; border: 1px" no-body>

                    <!-- Fields' Card Header -->
                    <div slot="header">
                        <div style="height: 29px" class="d-flex w-100 justify-content-between align-items-center">
                            <h5 style="margin-bottom: 0">
                                <b v-if="field._id === null">Fields</b>
                                <b v-else>Edit Field</b>
                            </h5>
                            <div>
                                <b-button v-if="field._id !== null"
                                          @click="deleteField"
                                          size="sm"
                                          variant="danger"
                                          style="margin-right: 10px">
                                    Delete
                                </b-button>
                                <b-button v-if="field._id !== null"
                                          size="sm" @click="clearEditFieldInfo"
                                          style="margin-right: 10px">
                                    Cancel
                                </b-button>
                                <b-button v-if="field._id !== null"
                                          @click="editField" size="sm"
                                          :disabled="(field.name === '' && field.type !== 'spacer') || field.type === '' || (field.size < 1 && field.type !== 'checkbox')"
                                          variant="success">
                                    Apply
                                </b-button>
                            </div>
                        </div>
                    </div>

                    <!-- Fields' Card Body -->
                    <div style="height: 100%; overflow-y: auto; padding: 20px;" class="categoryBackgroundColor">
                        <transition name="slide-fade-right" mode="out-in">
                            <!-- Edit Field Card Body -->
                            <div v-if="field._id !== null">
                                <h4 v-if="field.title" style="display: flex; justify-content: center;">
                                    <span style="text-decoration: underline;">Field Type: {{field.title}}</span>&nbsp;
                                </h4>
                                <b-input-group v-if="field.type !== 'spacer'" prepend="Field Name:" style="margin-bottom: 10px">
                                    <b-form-input v-model="field.name" :formatter="formatName" class="input-height editFieldBackgroundColor"/>
                                </b-input-group>
                                <b-input-group v-if="field.type !== 'checkbox' && field.type !== 'richTextBox' && field.type !== 'address' && field.type !== 'inmate' && field.type !== 'officer'" prepend="Field Size:" style="margin-bottom: 10px">
                                    <b-form-select v-model="field.size" :options="sizeOptions" default="3" class="input-height editFieldBackgroundColor"/>
                                </b-input-group>
                                <b-input-group v-if="field.type !== 'spacer'" prepend="Field Description:" style="margin-bottom: 10px">
                                    <b-form-input v-model="field.description" class="input-height editFieldBackgroundColor" :formatter="formatDescription"/>
                                </b-input-group>
                                <div v-if="field.type.includes('ropdownBox')">
                                    <div style="padding: 10px 0 5px 5px; font-size: 20px">
                                        Field Options
                                        <fa-icon :icon="['fas', 'circle-plus']" @click="addValue" title="Add Another Option"/>
                                    </div>
                                    <div v-for="(value, index) in field.values" :key="'value' + index">
                                        <b-input-group prepend="Option:" style="margin-bottom: 10px">
                                            <b-form-input v-model="field.values[index]" class="input-height editFieldBackgroundColor" :formatter="formatName"/>
                                            <b-input-group-append @click="deleteOption(index)" v-if="field.values.length > 1" title="Remove Option">
                                                <b-input-group-text style="cursor: pointer;">
                                                    <fa-icon :icon="['fas', 'close']"/>
                                                </b-input-group-text>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </div>
                                </div>
                                <b-input-group v-if="field.type === 'richTextBox'" prepend="Max Length:" style="margin-bottom: 10px">
                                    <b-form-input type="number" min="0" max="99999" v-model="field.textLength" placeholder="If 0, then no max text length."/>
                                </b-input-group>
                                <b-input-group v-if="field.type !== 'spacer'">
                                    <b-input-group-append class="rounded">
                                        <b-input-group-text v-if="field.type !== 'officer' && field.type !== 'inmate'" style="margin-right: 20px;">
                                            <b-form-checkbox v-model="field.required" style="cursor: pointer" size="sm" :checked="true" :unchecked-value="false"/> Required
                                        </b-input-group-text>
                                        <b-input-group-text v-if="field.type !== 'officer' && field.type !== 'inmate'" style="margin-right: 20px;">
                                            <b-form-checkbox v-model="field.editable" style="cursor: pointer" size="sm" :checked="true" :unchecked-value="false"/> Editable
                                        </b-input-group-text>
                                        <b-input-group-text v-if="field.type === 'time' || field.type === 'dateTime'">
                                            <b-form-checkbox v-model="field.format.military" style="cursor: pointer" size="sm" :checked="true" :unchecked-value="false"/> Military Time
                                        </b-input-group-text>
                                    </b-input-group-append>
                                </b-input-group>
                                <div v-if="field.type === 'date' || field.type === 'time' || field.type === 'dateTime'">
                                    <hr color="white">
                                    <h4 style="display: flex; justify-content: center;">
                                        <span style="text-decoration: underline;">Available Formats</span>&nbsp;
                                    </h4>
                                </div>
                                <div v-if="field.type === 'date' || field.type === 'dateTime'">
                                    <b-input-group prepend="Date Formats" style="margin-bottom: 10px">
                                        <b-form-select v-model="field.format.date"
                                                       :options="dateFormats"
                                                       style="cursor:pointer;"
                                                       class="input-height editFieldBackgroundColor"/>
                                    </b-input-group>
                                </div>
                                <div v-if="field.type === 'time' || field.type === 'dateTime'">
                                    <b-input-group prepend="Time Formats">
                                        <b-form-select v-model="field.format.time"
                                                       :options="timeFormats"
                                                       style="cursor:pointer;"
                                                       class="input-height editFieldBackgroundColor"/>
                                    </b-input-group>
                                </div>
                                <div v-if="field.type === 'officer' || field.type === 'inmate'">
                                    <hr color="white">
                                    <h4 style="display: flex; justify-content: center;">
                                        <span style="text-decoration: underline;">Available Fields</span>&nbsp;
                                    </h4>
                                </div>
                                <div v-if="field.type === 'officer'">
                                    <draggable group="fields" v-model="autoFillOfficerFields" @change="refillAutofillOfficerField">
                                        <div v-for="field in autoFillOfficerFields" style="padding: 5px 0;" :key="field._id">
                                            <b-input-group :prepend="field.name">
                                                <b-form-input style="cursor: pointer;" disabled class="input-height"/>
                                            </b-input-group>
                                        </div>
                                    </draggable>
                                </div>
                                <div v-if="field.type === 'inmate'">
                                    <draggable group="fields" v-model="autoFillInmateField" @change="refillAutofillInmateField">
                                        <div v-for="field in autoFillInmateField" style="padding: 5px 0;" :key="field._id">
                                            <b-input-group :prepend="field.name">
                                                <b-form-input style="cursor: pointer;" disabled class="input-height"/>
                                            </b-input-group>
                                        </div>
                                    </draggable>
                                </div>
                            </div>

                            <!-- Fields' Card Body -->
                            <draggable v-else group="fields" v-model="fieldOptions" @change="refillFields">
                                <div v-for="field in fieldOptions" style="padding: 10px 0;" :key="field._id">
                                    <b-input-group v-if="field.type === 'textBox'" :prepend="field.name">
                                        <b-form-input style="cursor: pointer;" disabled placeholder="text" class="input-height"/>
                                        <b-input-group-append>
                                            <b-input-group-text style="padding: 1px 15px;">
                                                <span style="font-style: italic; font-weight: bold; font-size: 20px;">T</span>
                                            </b-input-group-text>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <b-input-group v-if="field.type === 'richTextBox'" :prepend="field.name">
                                        <b-form-input style="cursor: pointer;" disabled placeholder="rich text" class="input-height"/>
                                        <b-input-group-append>
                                            <b-input-group-text>
                                                <fa-icon :icon="['fas', 'align-left']" fixed-width/>
                                            </b-input-group-text>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <b-input-group v-if="field.type === 'checkbox'">
                                        <b-input-group-text>
                                            <b-checkbox disabled style="cursor: pointer" size="sm"/> Check Box
                                        </b-input-group-text>
                                    </b-input-group>
                                    <b-input-group v-if="field.type.includes('ropdownBox')" :prepend="field.name">
                                        <b-form-input disabled style="cursor: pointer;" class="input-height"
                                                      placeholder="drop down box"/>
                                        <b-input-group-append>
                                            <b-input-group-text>
                                                <fa-icon :icon="['fas', 'caret-down']" fixed-width/>
                                            </b-input-group-text>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <b-input-group v-if="field.type === 'date'" :prepend="field.name">
                                        <b-form-input style="cursor: pointer;" disabled class="input-height" placeholder="MM/DD/YYYY"/>
                                        <b-input-group-append>
                                            <b-input-group-text>
                                                <fa-icon :icon="['fas', 'calendar']" fixed-width/>
                                            </b-input-group-text>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <b-input-group v-if="field.type === 'dateTime'" :prepend="field.name">
                                        <b-form-input style="cursor: pointer;" disabled class="input-height" placeholder="MM/DD/YYYY HH/MM/SS"/>
                                        <b-input-group-append>
                                            <b-input-group-text style="padding: 3px 4px;">
                                                <fa-icon :icon="['fas', 'calendar']" fixed-width style="font-size: 11px; position: relative; top: -6px;"/>
                                                <span style="font-size: 18px; position: relative; transform: rotate(35deg); top: -2px; left:2px;">|</span>
                                                <fa-icon :icon="['far', 'clock']" fixed-width style="font-size: 11px; position: relative; top: 6px;"/>
                                            </b-input-group-text>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <b-input-group v-if="field.type === 'number'" :prepend="field.name">
                                        <b-form-input style="cursor: pointer;" disabled type="number" placeholder="number" class="input-height"/>
                                        <b-input-group-append>
                                            <b-input-group-text>
                                                <fa-icon :icon="['fas', 'hashtag']" fxied-width/>
                                            </b-input-group-text>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <b-input-group v-if="field.type === 'time'" :prepend="field.name">
                                        <b-form-input style="cursor: pointer;" disabled placeholder="XX:XX" class="input-height"/>
                                        <b-input-group-append>
                                            <b-input-group-text>
                                                <fa-icon :icon="['far', 'clock']" fixed-width/>
                                            </b-input-group-text>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <b-input-group v-if="field.type === 'phoneNumber'" :prepend="field.name">
                                        <b-form-input disabled placeholder="(XXX) XXX-XXXX" class="input-height"/>
                                        <b-input-group-append>
                                            <b-input-group-text>
                                                <fa-icon :icon="['fas', 'phone']" fixed-width/>
                                            </b-input-group-text>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <b-input-group v-if="field.type === 'address'" :prepend="field.name">
                                        <b-form-input disabled placeholder="address" class="input-height"/>
                                        <b-input-group-append>
                                            <b-input-group-text>
                                                <fa-icon :icon="['fas', 'house']" fixed-width/>
                                            </b-input-group-text>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <b-input-group v-if="field.type === 'spacer'">
                                        <b-form-input style="background-color: #121245; cursor: pointer;" disabled
                                                      placeholder="Spacer" class="input-height"/>
                                    </b-input-group>
                                    <b-input-group v-if="field.type === 'inmate'" :prepend="field.name">
                                        <b-form-input style="cursor: pointer;" disabled placeholder="Firstname, LastName, MiddleName, MID, DOB, SSN" class="input-height"/>
                                    </b-input-group>
                                    <b-input-group v-if="field.type === 'officer'" :prepend="field.name">
                                        <b-form-input style="cursor: pointer;" disabled placeholder="FirstName, LastName star#" class="input-height"/>
                                    </b-input-group>
                                </div>
                            </draggable>
                        </transition>
                    </div>

                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
import datePicker from "vue-bootstrap-datetimepicker";
import draggable from "vuedraggable";
import Report from '@/services/report';
import {v4 as uuidv4} from 'uuid';
import PdfPreview from "@/views/expansion_modules/reports/case_components/PdfPreview";
import PubSub from 'pubsub-js'

export default {
    name: "TemplateCreator",
    props: ['id'],
    components: {
        datePicker,
        draggable,
        PdfPreview,
    },
    data() {
        return {
            displayInfo: false,
            addingFields: 'Drag and drop fields from the fields box in bottom right, into the category that you would like to add it to.',
            removingFields: 'Drag and drop fields from the category into the fields box located at the bottom right of the screen. Or just '+
                'select the field to delete and press the delete button inside the fields box.',
            editingFields: 'Click on a field inside of a category, the fields box will now have everything about that field that can be changed. '+
                'After making the desired changes, the apply button must be pressed or the changes will not be saved.',
            savingTemplate: 'To save the template, click the green save template button. This can only be pressed once all the red outlined'+
                'text boxes are filled in will some form of information.',
            singeItemCategory: "Select this if you only want there to be one item of this category ever",
            winCounty: true,
            categoryDragHover: null,
            deleteVerificationString: '',
            deleteCategoryModal: false,
            deleteTemplateModal: false,
            emptyDate: null,
            selectedCategoryId: null,
            selectedFieldId: null,
            selectedFieldValues: [],
            selectedCustomFieldId: null,
            fieldTypeOptions: [
                {text: 'Address', value: 'address'},
                {text: 'Date', value: 'date'},
                {text: 'Date/Time', value: 'dateTime'},
                {text: 'Dropdown Box', value: 'dropdownBox'},
                {text: 'Searchable Dropdown', value: 'searchableDropdownBox'},
                {text: 'Number', value: 'number'},
                {text: 'Phone Number', value: 'phoneNumber'},
                {text: 'Rich Text Box', value: 'richTextBox'},
                {text: 'Text Box', value: 'textBox'},
                {text: 'Time', value: 'time'},
                {text: 'Spacer', value: 'spacer'},
                {text: 'Check Box', value: 'checkbox'},
            ].sort((a, b) => a.text.localeCompare(b.text)),
            officerTypeOptions: [
                {text: 'Name and Badge', value: 'nameAndBadge'},
                {text: 'First Name', value: 'firstName'},
                {text: 'Last Name', value: 'lastName'},
                {text: 'Position', value: 'officerType'},
                {text: 'Star Number', value: 'starNumber'},
                {text: 'Agency', value: 'agency'},
                {text: 'Status', value: 'active'},
            ].sort((a, b) => a.text.localeCompare(b.text)),
            inmateTypeOptions: [
                {text: 'First Name', value: 'firstName'},
                {text: 'Last Name', value: 'lastName'},
                {text: 'Age', value: 'age'},
                {text: 'Alias', value: 'aliasAKA'},
                {text: 'Date of Birth', value: 'dateOfBirth'},
                {text: 'Ethnicity', value: 'ethnicity'},
                {text: 'Facial Hair', value: 'facialHair'},
                {text: 'Gender', value: 'gender'},
                {text: 'Hair Color', value: 'hairColor'},
                {text: 'Hair Style', value: 'hairStyle'},
                {text: 'Height (ft)', value: 'heightFeet'},
                {text: 'Height (in)', value: 'heightInches'},
                {text: 'Height (ft & in)', value: 'heightBoth'},
                {text: 'Home Phone Number', value: 'homePhone'},
                {text: 'Left Eye Color', value: 'leftEyeColor'},
                {text: 'MID', value: 'mid'},
                {text: 'Middle Name', value: 'middleName'},
                {text: 'Right Eye Color', value: 'rightEyeColor'},
                {text: 'Race', value: 'race'},
                {text: 'SSN', value: 'ssn'},
                {text: 'US Marshalls Number', value: 'usMarshallsNumber'},
                {text: 'Weight', value: 'weight'},
                {text: 'Bed Number', value: 'currentLocation.bed'},
                {text: 'Cell Number', value: 'currentLocation.cell'},
                {text: 'Pod Number', value: 'currentLocation.pod'},
                {text: 'Floor Number', value: 'currentLocation.floor'},
                {text: 'Street Number', value: 'secondaryAddress.streetNumber'},
                {text: 'Street Name', value: 'secondaryAddress.streetName'},
                {text: 'Apartment Number', value: 'secondaryAddress.apartment'},
                {text: 'City', value: 'secondaryAddress.city'},
                {text: 'State', value: 'secondaryAddress.state'},
                {text: 'Zip', value: 'secondaryAddress.zip'},
                {text: 'Country', value: 'secondaryAddress.country'},
                {text: 'Language', value: 'primaryLanguage'},
                {text: 'Citizenship', value: 'citizenship'},
                {text: 'Marital Status', value: 'maritalStatus'},
                {text: 'Charges', value: 'chargeHistory'}

            ].sort((a, b) => a.text.localeCompare(b.text)),
            fieldOptions: [],
            sizeOptions: [
                {text: 'small', value: 3},
                {text: 'medium', value: 6},
                {text: 'large', value: 9},
                {text: 'extra large', value: 12}
            ],
            dateFormats: [
                {text: 'MM/DD/YY', value: 'MM/DD/YY'},
                {text: 'DD/MM/YY', value: 'DD/MM/YY'},
                {text: 'YY/MM/DD', value: 'YY/MM/DD'},
                {text: 'YY/DD/MM', value: 'YY/DD/MM'},
            ],
            timeFormats: [
                {text: 'H:mm:ss', value: 'H:mm:ss'},
                {text: 'H:mm', value: 'H:mm'},
                {text: 'H', value: 'H'},
                {text: 'mm:ss', value: 'mm:ss'},
                {text: 'mm', value: 'mm'},
                {text: 'ss', value: 'ss'},
            ],
            category: {
                _id: uuidv4(),
                name: '',
                item: {
                    name: '',
                    fields: []
                },
            },
            template: {
                categories: [],
                name: '',
                description: '',
            },
            field: {
                _id: null,
                name: '',
                type: '',
                size: 3,
                description: '',
                required: false,
                editable: true,
                textLength: 0,
                format: {},
                values: [''],
            },
            saveCountDown: 0,
            autoFillOfficerFields: [],
            autoFillInmateField: [],
            showPdf: false,
            pubsubs: [],
            setIntervals: [],
        }
    },

    created() {
        if (this.template.categories.length === 0) {
            this.addCategory();
        }
        if (this.winCounty === true) {
            this.fieldTypeOptions.push(
                {text: 'Officer', value: 'officer'},
                {text: 'Inmate', value: 'inmate'},
            );
        }
        this.refillFields();
        this.refillAutofillOfficerField();
        this.refillAutofillInmateField();
        this.fillTemplate();
    },
    methods: {
        isSelectedField(categoryID, fieldId) {
            let border = '';
            if (this.selectedCategoryId === categoryID && this.selectedFieldId === fieldId) {
                border =  'border border-primary rounded';
            } else {
                border = 'border border-dark rounded';
                if (this.selectedFieldValues !== undefined && this.selectedFieldValues !== []) {
                    this.selectedFieldValues.forEach(field => {
                        if (field.id === fieldId) {
                            border = 'border border-success rounded';
                        }
                    });
                }
            }
            return border;
        },
        addFieldEvent(event, categoryID) {
            this.categoryDragHover = null;
            try {
                this.editFieldMode(categoryID, event.added.element._id);
            } catch (err) {
                console.error(e);
            }
        },
        fillTemplate() {
            if (this.$route.params.id !== 'temp') {
                this.getTemplate(this.$route.params.id);
            }
        },
        refillFields() {
            this.fieldOptions = [];
            this.fieldTypeOptions.forEach(field => {
                this.fieldOptions.push({
                    _id: uuidv4(),
                    name: field.text,
                    type: field.value,
                    size: field.value === 'address' || field.value === 'richTextBox' || field.value === 'inmate' || field.value === 'officer'? 12 : 3,
                    description: '',
                    required: false,
                    editable: true,
                    textLength: 0,
                    values: [''],
                    format: field.value === 'date' || field.value === 'time' || field.value === 'dateTime' ? {date: 'MM/DD/YY', time: 'H:mm:ss', military: true} : {},
                });
            });
        },
        refillCustomFields(options, event) {
            if (this.selectedCustomFieldId !== null && event.added === undefined) {
                let index1 = this.template.categories.findIndex(category => {
                    return category._id === this.selectedCategoryId;
                });
                let index2 = this.template.categories[index1].item.fields.findIndex(field => {
                    return field._id === this.selectedCustomFieldId;
                });
                this.removeLeftovers();
                if (this.template.categories[index1].item.fields[index2].values[0] === '') {
                    this.template.categories[index1].item.fields[index2].values[0] = Object.assign({}, {
                        id: event.removed.element._id,
                        link: event.removed.element.link
                    });
                } else {
                    this.template.categories[index1].item.fields[index2].values.push({
                        id: event.removed.element._id,
                        link: event.removed.element.link
                    });
                }
            }
            let array = [];
            options.forEach(field => {
                array.push({
                    _id: uuidv4(),
                    name: field.text,
                    title: field.text,
                    type: field.text === "Charges" || field.text === "Alias" ? 'richTextBox' : 'textBox',
                    link: field.value,
                    size: field.text === "Charges" || field.text === "Alias" ? 12 : 3,
                    description: '',
                    required: false,
                    editable: true,
                    textLength: 0,
                    values: ['']
                });
            });
            return array;
        },
        refillAutofillOfficerField(event) {
            this.autoFillOfficerFields = this.refillCustomFields(this.officerTypeOptions, event);
        },
        refillAutofillInmateField(event) {
            this.autoFillInmateField = this.refillCustomFields(this.inmateTypeOptions, event);
        },
        async editFieldMode(categoryId, fieldId, fieldValues) {
            if (this.selectedCategoryId === categoryId && this.selectedFieldId === fieldId) {
                this.clearEditFieldInfo();
                this.selectedFieldValues = undefined;
            } else {
                this.selectedCategoryId = categoryId;
                this.selectedFieldId = fieldId;
                this.selectedFieldValues = fieldValues;
                let index1 = this.template.categories.findIndex(category => {
                    return category._id === categoryId;
                })
                let index2 = this.template.categories[index1].item.fields.findIndex(field => {
                    return field._id === fieldId;
                })
                this.field = await JSON.parse(JSON.stringify(this.template.categories[index1].item.fields[index2]))
                if (this.field.type === 'officer' || this.field.type === 'inmate') {
                    this.selectedCustomFieldId = fieldId;
                } else {
                    this.selectedCustomFieldId = null;
                }
            }
        },
        addCategory() {
            this.category._id = uuidv4();
            this.template.categories.push(JSON.parse(JSON.stringify(this.category)));
        },
        async editField() {
            let index1 = this.template.categories.findIndex(category => {
                return category._id === this.selectedCategoryId;
            })
            let index2 = this.template.categories[index1].item.fields.findIndex(field => {
                return field._id === this.selectedFieldId;
            })
            await this.removeLeftovers();
            this.template.categories[index1].item.fields[index2] = await JSON.parse(JSON.stringify(this.field));
            this.clearEditFieldInfo();
        },
        openDeleteCategoryModal(_id) {
            this.deleteVerificationString = '';
            this.selectedCategoryId = _id;
            this.deleteCategoryModal = true;
        },
        openDeleteTemplateModal() {
            this.deleteVerificationString = '';
            this.deleteTemplateModal = true;
        },
        deleteCategory() {
            let index = this.template.categories.findIndex(category => {
                return category._id === this.selectedCategoryId;
            })
            this.template.categories.splice(index, 1);
            this.deleteCategoryModal = false;
        },
        deleteField() {
            let index1 = this.template.categories.findIndex(category => {
                return category._id === this.selectedCategoryId;
            });
            let index2 = this.template.categories[index1].item.fields.findIndex(field => {
                return field._id === this.selectedFieldId;
            });
            this.template.categories[index1].item.fields.splice(index2, 1);
            this.clearEditFieldInfo();
        },
        clearEditFieldInfo() {
            this.selectedFieldId = null;
            this.selectedCategoryId = null;
            this.selectedFieldValues = undefined;
            this.field._id = null;
            this.field.type = '';
            this.field.name = '';
            this.field.description = '';
            this.field.size = 3;
            this.field.textLength = 0;
            this.format = {};
            this.field.values = [''];
            this.field.editable = true;
        },
        removeLeftovers() {
            //field.values was repurposed for custom fields to show which fields are linked to the custom field
            if (!this.field.type.includes('ropdownBox') && this.field.type !== 'inmate' && this.field.type !== 'officer') {
                this.field.values = [''];
            }
            if (this.field.type === 'richTextBox' || this.field.type === 'address' || this.field.type === 'inmate' || this.field.type === 'officer') {
                this.field.size = 12;
            } else if (this.field.type === 'checkbox') {
                this.field.size = 3;
            }
        },
        addValue() {
            this.field.values.push('');
        },
        deleteOption(index) {
            this.field.values.splice(index, 1);
        },
        formatName(e) {
            //this limits the max characters inside a name
            return String(e).substring(0, 50);
        },
        formatDescription(e) {
            //this limits the max characters inside a description
            return String(e).substring(0, 150);
        },
        postPutTemplate() {
            //if the id exists update the template, else make a new template
            if (this.template._id !== undefined) {
                Report.putTemplate(this.template._id, this.template).then(() => {
                    this.getTemplate(this.template._id);
                    this.$mToast({
                        title: 'Template Saved!',
                        style: 'success',
                    });
                });
            } else {
                Report.postTemplate(this.template).then(result => {
                    location.href = '/#/template/' + result.data._id;
                    this.getTemplate(result.data._id);
                    this.$mToast({
                        title: 'Template Created!',
                        style: 'success',
                    });
                });
            }
        },
        deleteTemplate() {
            //remove template from database then re-route back to template page
            if (this.deleteVerificationString === 'template') {
                Report.deleteTemplate(this.template._id).then(() => {
                    this.$mToast({
                        title: 'Template Deleted!',
                        style: 'success',
                    });
                    location.href = '/#/templates';
                });
            }
        },
        getTemplate(templateId) {
            Report.getTemplateById(templateId).then(template => {
                this.template = template.data;
            });
        },
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        filledOut(i) {
            return !(i === null || i === undefined || i === '' || i === 0);
        },
    },
    computed: {
        preventBadSave() {
            let result = false;
            if(this.template.name === '') {
                result = true;
            }
            this.template.categories.forEach(category => {
                if(category.name === '' || category.item.name === '' || category.item.fields.length === 0) {
                    result = true;
                }
            })
            return result;
        }
    },
    beforeDestroy() {
        for (let i = 0; i < this.pubsubs.length; i++) {
            PubSub.unsubscribe(this.pubsubs[i]);
        }
        for (let i = 0; i < this.setIntervals.length; i++) {
            clearInterval(this.setIntervals[i]);
        }
    }
}
</script>

<style scoped>
.mainContainer {
    height: calc(100vh - 152px);
    margin: -24px -30px 0 -30px;
}
.collapsed .when-open,
.not-collapsed .when-closed {
    display: none;
}
.templateInfo {
    width: 100%;
    flex: 1;
    padding: 10px;
}
.fieldsInfo {
    overflow-y: hidden;
    flex: 3;
    width: 100%;
    padding: 10px
}
.input-height {
    height: inherit;
}
.infoBox {
    border-radius: 25px;
    position: absolute;
    width: 400px;
    background-color: #464646;
    z-index: 5;
    padding: 15px;
    border-color: #161616;
    border-style: solid;
    border-width: 5px;
}
.infoTitle {
    text-decoration: underline;
    display: flex;
    justify-content: center;
}
.infoText {
    font-size: 13px;
}
.categoryBackgroundColor {
    background-color: #1e1e1e !important;
}
.itemBackgroundColor {
    background-color: #323234 !important;
}
.fieldBackgroundColor {
    background-color: #1a1a1a !important;
}
.editFieldBackgroundColor {
    background-color: #303032 !important;
}
.bigFieldBackgroundColor {
    margin-bottom: 0;
    display: flex;
    background-color: #2c2c2c !important;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #888888 !important;
    opacity: 1; /* Firefox */
}
input:disabled {
    background-color: #303032;
}
.custom-select {
    background: transparent;
}
</style>
